<template>
 <div class="flix-text-success flix-text-center">
  <div class="flex flex-center" v-if="saving">
    <b-flix-loader></b-flix-loader>
  </div>
  <div v-else>
    <div class="ok" >
      <b-flix-icon icon="check"></b-flix-icon>
    </div>
    <div>
      {{ $t('message.successfullSaved') }}
    </div>
  </div>
 </div>
</template>
<script>
export default {
  name: 'defaultComponent',
  components: {},
  props: {},
  data () {
    return {
      saving: true
    }
  },
  computed: {
  },
  mounted () {
    this.setSaving()
  },
  methods: {
    async setSaving () {
      await new Promise((resolve) => setTimeout(() => (resolve()), 500))
      this.saving = false
      await new Promise((resolve) => setTimeout(() => (resolve()), 2000))
      this.$emit('save')
    }
  }
}
</script>
<style lang="sass" scoped>
  .ok
    font-size: 4rem
</style>
