import { render, staticRenderFns } from "./savingAnimation.vue?vue&type=template&id=4e657d56&scoped=true"
import script from "./savingAnimation.vue?vue&type=script&lang=js"
export * from "./savingAnimation.vue?vue&type=script&lang=js"
import style0 from "./savingAnimation.vue?vue&type=style&index=0&id=4e657d56&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e657d56",
  null
  
)

export default component.exports